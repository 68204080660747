<!--
 * @Author: Scarlett Xiao
 * @Date: 2021-10-19 16:27:54
 * @LastEditors: Scarlett Xiao
 * @LastEditTime: 2021-10-20 10:41:43
-->
<template>
  <div
    class="my-video"
    id="my-video"
  >
    <VideoShow
      v-if="show"
      :videoUrl="videoUrl"
      :token="token"
      class="video"
    ></VideoShow>
  </div>
</template>

<script>
import VideoShow from './video-show.vue'

export default {
  name: 'video',
  components: {
    VideoShow,
  },
  props: {
    videoUrl: String, // 视频链接
    token: String, // 视频token
    videoCode: String, // 设备编码
    videoType: String, // 设备编码
    videoId: String, // 自定义视频载体id
  },
  data() {
    return {
      show: null, // 视频展示
    }
  },
  methods: {},
  created() {
    this.show = true
  },
  mounted() {
    // console.group('mounted 组件挂载完毕状态===============》')
    // if (this.videoUrl) {
    //   setTimeout(() => {
    //     this.player = new EZUIKit.EZUIPlayer('myPlayer')
    //     setTimeout(() => {
    //       this.player.play()
    //     }, 30)
    //     this.player.on('start', function (e) {
    //       console.log('加载成功')
    //     })
    //     this.player.on('error', function () {
    //       console.log('视频连接错误')
    //     })
    //   }, 0)
    // }
    // 引用 element-resize-detector，用户监听元素宽度变化
    // const elementResizeDetectorMaker = require('element-resize-detector')
    // // 创建实例
    // const erd = elementResizeDetectorMaker()
    // erd.listenTo(document.getElementById('my-video'), (element) => {
    //   this.show = false
    //   setTimeout(() => {
    //     this.show = true
    //   }, 5)
    // })
  },

  beforeDestroy() {
    // 停止播放
    // this.player && this.player.destroy() && this.player.stop()
  },
}
</script>
<style scoped lang="less">
.my-video {
  width: 100%;
  height: 100%;
  // min-height: 300px;
  // height: 496px;
  overflow: hidden;
}

.PTZ-BOX {
  position: relative;
  .video {
    position: absolute;
    z-index: 1;
  }
  // &:hover > .ptz-item {
  //   visibility: visible;
  // }
  .ptz-item {
    // visibility: hidden;
    position: absolute;
    z-index: 99;
    bottom: 10px;
    right: 10px;
    transition: all 0.5s ease-in;
  }
}
</style>
