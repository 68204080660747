<!--
 * @Author: Scarlett Xiao
 * @Date: 2021-10-19 16:27:54
 * @LastEditors: Scarlett Xiao
 * @LastEditTime: 2021-10-20 10:41:43
-->
<template>
  <div
    class="my-video PTZ-BOX"
    id="my-video"
  >
    <VideoShow2
      v-if="show"
      :videoUrl="videoUrl"
      :token="token"
      class="video"
    ></VideoShow2>
    <!-- 云台(球机转动) -->
    <PTZ
      v-if="videoType == 'haikang-ball'"
      class="ptz-item"
      :videoCode="videoCode"
      :token="token"
    ></PTZ>

  </div>
</template>

<script>
import VideoShow2 from './video-show2.vue'
import PTZ from './PTZ.vue'

export default {
  name: 'video',
  components: {
    VideoShow2,
    PTZ,
  },
  props: {
    videoUrl: String, // 视频链接
    token: String, // 视频token
    videoCode: String, // 设备编码
    videoType: String, // 设备编码
  },
  data() {
    return {
      show: null, // 视频展示
    }
  },
  methods: {},
  created() {
    this.show = false
    setTimeout(() => {
      this.show = true
    }, 0)
  },
  mounted() {},
  beforeDestroy() {},
}
</script>
<style scoped lang="less">
.my-video {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.PTZ-BOX {
  position: relative;
  .video {
    position: absolute;
    z-index: 1;
  }
  // &:hover > .ptz-item {
  //   visibility: visible;
  // }
  .ptz-item {
    // visibility: hidden;
    position: absolute;
    z-index: 99;
    bottom: 10px;
    right: 10px;
    transition: all 0.5s ease-in;
  }
}
</style>
