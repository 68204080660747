<!--
 * @Author: Scarlett Xiao
 * @Date: 2021-10-19 16:27:54
 * @LastEditors: Scarlett Xiao
 * @LastEditTime: 2021-10-20 10:41:43
-->
<template>
  <div
    id="myplayer"
    style="width: 100%; height: 100%; object-fit:fill;"
  >
  </div>
</template>

<script>
// 引用视频直播插件
import EZUIKit from 'ezuikit-js'

export default {
  name: 'video-show2',
  props: {
    videoUrl: String, // 视频链接
    token: String, // 视频token
  },
  data() {
    return {
      player: null,
    }
  },
  created() {
    setTimeout(() => {
      if (this.videoUrl) {
        this.player = new EZUIKit.EZUIKitPlayer({
          id: 'myplayer',
          autoplay: true,
          url: this.videoUrl,
          accessToken: this.token,
          // decoderPath: "",
          // handleError: (data: any) => console.log("错误", data),
          // handleSuccess: handleSuccess,

          template: 'simple', // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
          // 视频上方头部控件
          // header: ["capturePicture", "save", "zoom"], // 如果templete参数不为simple,该字段将被覆盖
          // plugin: ["talk"], // 加载插件，talk-对讲
          // 视频下方底部控件
          // footer: ["talk", "broadcast", "hd", "fullScreen"], // 如果template参数不为simple,该字段将被覆盖
          audio: 1, // 是否默认开启声音 0 - 关闭 1 - 开启
          // openSoundCallBack: (data: any) => console.log("开启声音回调", data),
          // closeSoundCallBack: (data: any) => console.log("关闭声音回调", data),
          // startSaveCallBack: (data: any) => console.log("开始录像回调", data),
          // stopSaveCallBack: (data: any) => console.log("录像回调", data),
          // capturePictureCallBack: (data: any) =>console.log("截图成功回调", data),
          // fullScreenCallBack: (data: any) => console.log("全屏回调", data),
          // getOSDTimeCallBack: (data: any) =>console.log("获取OSDTime回调", data),
        })
      }
    }, 0)
  },
  // beforeDestroy() {
  //   // 停止播放
  // this.player && this.player.destroy() && this.player.stop()
  // },

  beforeDestroyed() {
    this.player.stop()
    this.player = null
  },
}
</script>
<style scoped lang="less">
.my-video {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

object,
embed  {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: fill;
}
</style>
