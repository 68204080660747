export const LogKeyName = {
 // 养殖数据资源池
BREEDING_DATA_RESOURCE_POOL:"养殖数据资源池",

BIG_DATA_REPORT_CENTER:"养殖报表",

A_PICTURE:"一张图",
APPLET: "小程序",
INDEX:"首页",
ROAMING_INDUSTRIAL_PARK:"漫游产业园",
INDUSTRIAL_PARK_SERVICES:"产业园服务",
NEWS_CENTER:"新闻中心",
//    需要前端访问接口记录
DOU_YIN:"品牌短视频",
//    需要前端访问接口记录
PUBLIC_SERVICES:"公共服务",
TRACEABILITY:"溯源集成系统"
}