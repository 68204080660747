import axios from 'axios'

const instance = axios.create({
  baseURL: 'https://api.qweather.com/v7',
  headers: {}
  // devapi  kaifa
});

const devinstance = axios.create({
  baseURL: 'https://devapi.qweather.com/v7',
  headers: {}
  // devapi  kaifa
});

// 专用潮汐接口
const lookupInstance = axios.create({
  baseURL: 'https://geoapi.qweather.com/v2/poi/lookup',
  headers: {}
})
// axios.defaults.baseURL = "https://api.heweather.net/s6/weather";
// https://api.qweather.com/v7/ocean/tide // 潮汐
// https://api.qweather.com/v7/weather/now // 实时天气
// https://devapi.qweather.com/v7/warning/now // 天气预警
// https://devapi.qweather.com/v7/grid-weather/now // 天气预警
let key = '50e57ac7ce5c44f0b0c14de3933a1f9e'
let devkey = 'e0e3a33e3a264118815cb4e8813417d8'
// 50e57ac7ce5c44f0b0c14de3933a1f9e  商业
// e0e3a33e3a264118815cb4e8813417d8  dev
let locationID = 101281001 // 湛江 Id

/**
 * type: 当天天气及未来3天天气预报
 *      now
 *      72h
 *      3d
 */
export const getWeather = function (type, location) {
  // if (type !== 'now' && type !== 'forecast' && type !== 'lifestyle') throw new Error('getWeather error: type类型错误');
  if (!location) throw new Error('getWeather error: location not defined');
  return devinstance.get(`/weather/${type}`, {
    params: {
      location,
      key: devkey
    }
  }).then(function (res) {
    return res.data;
  })
}

// 获取潮汐数据
export const getOcean = async function (type, location, date) {
  // if (type !== 'now' && type !== 'forecast' && type !== 'lifestyle') throw new Error('getWeather error: type类型错误');
  if (!location) throw new Error('getWeather error: location not defined');
  let POI = await lookupInstance.get(``, {
    params: {
      type,
      location,
      key: devkey
    }
  })
  if (POI.data.code === '200') {
    // console.log('POI', POI)
    let locationId = POI.data.poi[0].id
    return instance.get(`/ocean/tide`, {
      params: {
        date,
        location: locationId,
        key
      }
    }).then(function (res) {
      return res.data;
    })
  } else {
    throw new Error('getOcean error: request fail');
  }
}

// 获取潮流数据
export const getOceanCur = async function (type, location, date) {
  // if (type !== 'now' && type !== 'forecast' && type !== 'lifestyle') throw new Error('getWeather error: type类型错误');
  if (!location) throw new Error('getWeather error: location not defined');
  let POI = await lookupInstance.get(``, {
    params: {
      type: 'CSTA',
      location,
      key: devkey
    }
  })
  if (POI.data.code === '200') {
    // console.log('POI', POI)
    let locationId = POI.data.poi[0].id
    return instance.get(`/ocean/currents`, {
      params: {
        date,
        location: locationId,
        key
      }
    }).then(function (res) {
      return res.data;
    })
  } else if (POI.data.code === '404') {
    // 临时解决
    return instance.get(`/ocean/currents`, {
      params: {
        date,
        location: 'P63989',
        key
      }
    }).then(function (res) {
      return res.data;
    })
  } else {
    throw new Error('getOceanCur error: request fail');
  }
}

export const getWarning = function () {
  return devinstance.get(`/warning/now`, {
    params: {
      location: locationID,
      key: devkey
    }
  }).then(function (res) {
    return res.data;
  })
}

export const initDay = async function () {
  let e = await this.getWeather('now', this.city);
  this.subWeather = e.now.cond_txt;
  this.curTemp = e.now.tmp;
  this.webIcon = e.now.cond_code;
}

export const initDayDetail = async function () {
  let fore = await this.getWeather('forecast', this.city);
  this.foreDays = fore.daily_forecast;
  let life = await this.getWeather('lifestyle', this.city);
  this.lifeStyle = life.lifestyle;
}

export const searchCity = function (location) {
  if (!location) throw new Error('searchCity error: location not defined');
  return devinstance.get(`now`, {
    params: {
      location,
      key: devkey
    }
  }).then(function (res) {
    return res;
  })
}
